import $ from 'jquery'
import '../node_modules/typeface-open-sans/index.css'
import '../node_modules/typeface-roboto/index.css'
import '../node_modules/material-icons/iconfont/material-icons.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import '../sass/global/combined-design-files.scss'
import './einfuerung'
import {generateIntroductionNavigationAndContent} from "./einfuerung"
window.Popper = require('popper.js').default;

window.$ = $
window.jQuery= $
/**
 * Created by altstaedt on 04.01.2019.
 */
$(document).ready(function () {
    // find the first input, select or textarea element and focus it for a better user experience
    let firstFormElement = document.querySelector("input, select, textarea")
    const detectDeviceType = function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop'
    }
    if (detectDeviceType() === 'Desktop' && firstFormElement !== null) {
        firstFormElement.focus()
    }


    // activate bootstraps tooltips
    $('[title]').tooltip()
    /*
     scroll to first form error message
     */
    let filledErrorElements = $('.error').filter(function (index, el) {
        // remove all error elements which are empty (no html content)
        return $(el).html().trim().length > 0
    })

    // scroll to and center the first error element if one exists
    if (filledErrorElements.length > 0)
        $('body').scrollTop(
            filledErrorElements.offset().top - window.innerHeight / 2
        );


    /**
     * einfuerung.hmtl
     */
    // while scrolling: highlight the navigation point which is currently at top top of the screen
    $('body')
        .css({position: 'relative'})
        .scrollspy({target: '#explanation-navbar'})

    // Highlight the selected navigation point when the hash in the url changes
    window.onhashchange = function () {
        $('.highlighted-headline').removeClass('highlighted-headline')
        $(location.hash).addClass('highlighted-headline')
    }

    try {
        const helpInformation = getHelpInformation()

        // generate and set the navigation and content if elements exist
        const navigationAndContent = generateIntroductionNavigationAndContent(helpInformation)
        $('#explanation-navbar nav').append(navigationAndContent.navigation)
        $('#explanations').append(navigationAndContent.content)
    } catch (e) {
        // The getHelpInformation function is only available in the einfuehrung.html. And only there it is used
        // So there is no need to throw an error here. This try-catch-block will prevent an error in the console.
    }


})