

export function generateIntroductionNavigationAndContent(tips) {

    let navigation = ''
    let content = ''


    for (let rubricI = 0; rubricI < tips.rubric.length; rubricI++) {
        let rubric = tips.rubric[rubricI]
        let ri = (rubricI + 1)
        let id = 'item-' + ri

        content += '<h4 id="' + id + '">' + rubric.items[0].title + '</h4>'
        content += '<p>' + rubric.items[0].explanation + '</p>'

        navigation += '<a class="nav-link" href="#' + id + '">' + rubric.items[0].title + '</a>\n'
        navigation += '<nav class="nav nav-pills flex-column">\n'
        for (let itemsIndex = 1; itemsIndex < rubric.items.length; itemsIndex++) {
            if (itemsIndex > 0) {
                let idr = id + '-' + (itemsIndex)


                content += '<h5 id="' + idr + '">' + rubric.items[itemsIndex].title + '</h5>'
                content += '<p>' + rubric.items[itemsIndex].explanation + '</p>'
                navigation += '  <a class="nav-link ml-3 my-1" href="#' + idr + '">' + rubric.items[itemsIndex].title + '</a>\n'
            }
        }
        navigation += '</nav>\n'

    }
    return {
        navigation: navigation,
        content: content
    }
}
